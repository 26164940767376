document.addEventListener('DOMContentLoaded', async () => {

    const debug = 0;

    let buttonFilterSelect = document.querySelectorAll('.filter-select');
    if(typeof(buttonFilterSelect) != 'undefined' && buttonFilterSelect != null) {
        buttonFilterSelect.forEach((item) => {
            item.addEventListener('click', () => {

                if (debug > 0) console.log("click on button.filter-select");
                if (debug == 1) console.log("item: ");
                if (debug == 1) console.log(item);

                item.classList.toggle('active');

            });
        });
    }

});
