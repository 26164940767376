// fallback language
var debug = 0;

const siteLanguage    = document.documentElement.lang.substr(0, 2); // sprache der seite
const browserLanguage = navigator.language.substr(0, 2);            // browserLanguage
const localStorageLanguage = localStorage.getItem('language');             // gespeicherte Sprache (localStorage)
const body = document.body;

if (debug > 0) console.log("siteLanguage: " + siteLanguage);
if (debug > 0) console.log("browserLanguage: " + browserLanguage);
if (debug > 0) console.log("localStorageLanguage: " + localStorageLanguage);

// keine sprache gespeichert
if (localStorageLanguage == null) {

    /*
    if (debug > 0) console.log("localStorageLanguage nicht gesetzt - keine sprache gespeichert");

    if (siteLanguage == browserLanguage) {
        if (debug > 0) console.log("siteLanguage == browserLanguage => localStorage setItem ");
        localStorage.setItem('language', siteLanguage);
    } else if (browserLanguage == "de" || browserLanguage == "fr" || browserLanguage == "en") {
        if (debug > 0) console.log("siteLanguage != browserLanguage => browserLanguage in de,fr, en => localStorage setItem ");
        if (debug > 0) console.log("localStorage setItem ");
        localStorage.setItem('language', browserLanguage);
        if (browserLanguage == "de") {
            window.location.href = '/';
        } else {
            window.location.href = '/' + browserLanguage;
        }
    }

     */

}
// sprache gesetzt, eventuell weiterleiten
// nur wenn ich auf der startseite bin
else if ((localStorageLanguage == "de" || localStorageLanguage == "fr" || localStorageLanguage == "en") && body.classList.contains('body-homepage')) {

    if (debug > 0) console.log("localStorageLanguage gesetzt - sprache gespeichert: " + localStorageLanguage);
    if (debug > 0) console.log("window.location.pathname: " + window.location.pathname);
    if (siteLanguage != localStorageLanguage) {
        if (window.location.pathname == "/de" || window.location.pathname == "/fr" || window.location.pathname == "/en" ) {
            if (debug > 0) console.log("forward to : " + localStorageLanguage);
            if (localStorageLanguage == "de") {
                window.location.href = '/';
            } else {
                window.location.href = '/' + localStorageLanguage;
            }
        } else {
            if (debug > 0) console.log("set localStorage to : " + siteLanguage);
            localStorage.setItem('language', siteLanguage);
        }
    }

}
