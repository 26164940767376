document.addEventListener('DOMContentLoaded', async () => {

    const navMenuBurger = document.querySelector('.btn-burger');
    const navigation = document.querySelector('#navigation');

    const body = document.querySelector('body');
    const observeItem = document.querySelectorAll('header');
    const observerItemFunction = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    body.classList.remove('navigation-fixed');
                    navigation.classList.remove('navigation-open');
                } else {
                    body.classList.add('navigation-fixed');
                }
            })
        }
    );
    observeItem.forEach(observeItem => {
        observerItemFunction.observe(observeItem);
    });
    
    navMenuBurger.addEventListener('click', function(e) {
        navigation.classList.toggle('navigation-open');
    });

    const searchButton = document.querySelectorAll('.btn-search');
    searchButton.forEach((button) => {
        button.addEventListener('click', () => {
            document.getElementById('searchOffcanvas').addEventListener('shown.bs.offcanvas', e => {
                document.getElementById('searchInputField').focus();
            })
        });
    });

});
