document.addEventListener('DOMContentLoaded', async () => {
    // Load CSRF cookie
    const response = await fetch('/api/csrf');
    if (!response.ok) {
        const message = `An error has occurred when retrieving the CSRF token: ${response.status}`;
        throw new Error(message);
    }

    // Get CSRF cookie as JSON and save it as global variable
    const csrf = await response.json();
    window.csrfTokenName = csrf.csrfTokenName;
    window.csrfTokenValue = csrf.csrfTokenValue;
});
