document.addEventListener('DOMContentLoaded', async () => {

    // productareas buttons
    document.querySelectorAll('.nav-productarea .nav-item').forEach(function(el){

        el.addEventListener('mouseover', function() {
            var screenDesktop = window.matchMedia("(min-width: 992px)")
            if (screenDesktop.matches) {
                var catId = el.getAttribute('data-catid');
                var componentId = el.getAttribute('data-componentid');
                document.querySelectorAll('.nav-productarea .nav-item-component-' + componentId).forEach(function(elInner){
                    elInner.classList.remove("active");
                });
                el.classList.add("active");
                document.querySelectorAll('.catimageblock .catimages-component-' + componentId).forEach(function(elInner){
                    elInner.classList.remove("active");
                    var catimagesId = elInner.getAttribute('data-catimagesid');
                    if (catimagesId == catId) {
                        elInner.classList.add("active");
                    }
                });
            }
        });
        
        el.addEventListener('click', function() {
            var catId = el.getAttribute('data-catid');
            var componentId = el.getAttribute('data-componentid');
            document.querySelectorAll('.nav-productarea .nav-item-component-' + componentId).forEach(function(elInner){
                elInner.classList.remove("active");
            });
            el.classList.add("active");
            document.querySelectorAll('.catimageblock .catimages-component-' + componentId).forEach(function(elInner){
                elInner.classList.remove("active");
                var catimagesId = elInner.getAttribute('data-catimagesid');
                if (catimagesId == catId) {
                    elInner.classList.add("active");
                }
            });
        });
    });

});
