import Splide
    from '@splidejs/splide';
import {
    storageSet,
    sessionStorageGet,
    sessionStorageSet,
    sessionStorageHas
} from './local-storage';

import * as FilePond
    from 'filepond';
import de
    from 'filepond/locale/de-de.js';
import fr
    from 'filepond/locale/fr-fr.js';
import en
    from 'filepond/locale/en-en.js';

document.addEventListener(
    'DOMContentLoaded',
    async () => {

        // Get a reference to the file input element
        const inputElement = document.querySelector(
            'input[type="file"]');

        const htmlLang = document.querySelector(
            'html').getAttribute(
            'lang').slice(
            0,
            2);

        const translations = {
            de: de,
            fr: fr,
            en: en,
        };
        const translation = translations[htmlLang] ||
            de;

        var labelIdle = 'Dateien hochladen <i class="fa-light fa-arrow-up-from-bracket ms-3"></i><span class="btn btn-primary d-block mt-2 filepond--label-action">Oder aus Ordner auswählen</span>';

        // Create a FilePond instance
        const pond = FilePond.create(
            inputElement,
            {
                maxFiles: 3,
                allowMultiple: true,
                credits: false,
                storeAsFile: true,
                labelIdle: labelIdle
            });

        pond.on('init', () => {
            const fb = document.querySelector('.filepond--browser');
            if(typeof(fb) != 'undefined' && fb != null) {
                fb.setAttribute(
                    'accept',
                    'application/pdf');
            }
        });


    });

// slider init
function splideInit() {
    var elms = document.getElementsByClassName(
        'splide');
    for (var i = 0; i <
    elms.length; i++) {
        if (elms[i].classList.contains(
            "is-active")) {
        }
        else {
            new Splide(
                elms[i]).mount();
        }
    }
}

function accessabilityButton() {

    const debug = 0;

    /* accessability handling */
    let accessabilityButton = document.querySelectorAll(
        '.accessability-button');
    const localStorage = window.localStorage;

    accessabilityButton.forEach(
        (item) => {
            item.addEventListener(
                'click',
                () => {

                    if (debug ==
                        1) console.log(
                        "localStorage: ");
                    if (debug ==
                        1) console.log(
                        localStorage);
                    if (debug ==
                        1) console.log(
                        "item: ");
                    if (debug ==
                        1) console.log(
                        item);

                    const accessabilityMode = item.dataset.accessabilityMode;
                    if (localStorage.getItem(
                            accessabilityMode) ===
                        null ||
                        localStorage.getItem(
                            accessabilityMode) ===
                        '1') {
                        localStorage.setItem(
                            accessabilityMode,
                            '0');
                    }
                    else {
                        localStorage.setItem(
                            accessabilityMode,
                            '1');
                    }
                    document.querySelector(
                        'body').classList.toggle(
                        accessabilityMode);
                });
        });

}

document.addEventListener(
    'DOMContentLoaded',
    async () => {

        // modals & greyscale
        document.querySelectorAll(
            '.modal').forEach(
            el => el.addEventListener(
                'shown.bs.modal',
                e => {
                    if (document.querySelector(
                        'body').classList.contains(
                        "grayscale")) {
                        document.querySelector(
                            'body').classList.remove(
                            "grayscale");
                        document.querySelector(
                            'body').classList.add(
                            "grayscale-hold");
                    }

                    let oberflaechenselect = document.getElementById(
                        "oberflaechenselect");
                    if (typeof (oberflaechenselect) !=
                        'undefined' &&
                        oberflaechenselect !=
                        null) {
                        if (oberflaechenselect.length >
                            1) {
                            document.querySelector(
                                '.oberflaechen-select-wrapper').classList.remove(
                                "d-none");
                        }
                    }

                }));
        document.querySelectorAll(
            '.modal').forEach(
            el => el.addEventListener(
                'hidden.bs.modal',
                e => {
                    if (document.querySelector(
                        'body').classList.contains(
                        "grayscale-hold")) {
                        document.querySelector(
                            'body').classList.remove(
                            "grayscale-hold");
                        document.querySelector(
                            'body').classList.add(
                            "grayscale");
                    }
                }));

        splideInit();

        // produkteinzelseite
        const colProductText = document.querySelector(
            '.col-product-text');
        const colProductTextMoreButton = document.querySelector(
            '.col-product-text-more');
        if (typeof (colProductTextMoreButton) !=
            'undefined' &&
            colProductTextMoreButton !=
            null) {
            colProductTextMoreButton.addEventListener(
                'click',
                function() {
                    //console.log("click colProductTextMoreButton");
                    colProductText.classList.remove(
                        "col-product-text");
                });
        }
        // buttonSlideUp
        const buttonSlideUpWrapper = document.querySelector(
            '.button-slide-up-wrapper');
        const buttonSlideUp = document.querySelector(
            '#button-slide-up');
        if (typeof (buttonSlideUp) !=
            'undefined' &&
            buttonSlideUp !=
            null) {
            buttonSlideUp.addEventListener(
                'click',
                function() {
                    //console.log("scroll buttonSlideUp");

                    var containerProductfamily = document.querySelector(
                        '.row-productfamily');
                    var top = containerProductfamily.scrollTop;
                    containerProductfamily.scroll(
                        {
                            top: top -
                                200,
                            left: 0,
                            behavior: 'smooth'
                        });
                    if (top <=
                        200) {
                        buttonSlideUpWrapper.classList.add(
                            "d-none");
                    }

                });
        }

        // buttonSlideDown
        const buttonSlideDown = document.querySelector(
            '#button-slide-down');
        if (typeof (buttonSlideDown) !=
            'undefined' &&
            buttonSlideDown !=
            null) {
            buttonSlideDown.addEventListener(
                'click',
                function() {
                    // console.log("scroll buttonSlideDown");

                    var containerProductfamily = document.querySelector(
                        '.row-productfamily');
                    var top = containerProductfamily.scrollTop;
                    containerProductfamily.scroll(
                        {
                            top: top +
                                200,
                            left: 0,
                            behavior: 'smooth'
                        });

                    buttonSlideUpWrapper.classList.remove(
                        "d-none");

                });
        }

        /*
        setProductFamilyHeight();

        window.addEventListener('resize', function() {
            setProductFamilyHeight();
        });
        */

        setAcc();

        let ctaProductlist = document.querySelectorAll(
            '.cta-productlist-wrapper .cta-productlist');
        if (typeof (ctaProductlist) !=
            'undefined' &&
            ctaProductlist !=
            null) {
            ctaProductlist.forEach(
                (el) => {
                    let width = el.offsetWidth;
                    el.style.minHeight = width +
                        "px";
                });
        }

    });

// acc prod einzelseite
function setAcc() {
    var screenWidth = window.matchMedia(
        "(min-width: 992px)")
    if (screenWidth.matches) {

        var loop = 1;
        var acc = document.querySelectorAll(
            '#productAccordion .accordion-item .accordion-button');
        if (typeof (acc) !=
            'undefined' &&
            acc !=
            null) {
            acc.forEach(
                (el) => {
                    //console.log("loop: " + loop);
                    el.classList.add(
                        "collapsed");
                    if (loop ==
                        1) {
                        el.classList.remove(
                            "collapsed");
                    }
                    loop++;
                });

        }

        var loop = 1;
        var acc = document.querySelectorAll(
            '#productAccordion .accordion-item .accordion-collapse');
        if (typeof (acc) !=
            'undefined' &&
            acc !=
            null) {
            acc.forEach(
                (el) => {
                    //console.log("loop: " + loop);
                    el.classList.remove(
                        "show");
                    if (loop ==
                        1) {
                        el.classList.add(
                            "show");
                    }
                    loop++;
                });
        }

        /*
        const accButton = document.querySelector('#productAccordion .accordion-item #productHeading2 .accordion-button');
        if(typeof(accButton) != 'undefined' && accButton != null) {
            accButton.classList.remove("collapsed");
        }

        const productCollapse2 = document.querySelector('#productAccordion .accordion-item #productCollapse2');
        if(typeof(productCollapse2) != 'undefined' && productCollapse2 != null) {
            productCollapse2.classList.add("show");
        }
         */

    }
}

htmx.on(
    'htmx:afterOnLoad',
    function(event) {
        //setProductFamilyHeight();
        splideInit();
        setLightbox();
        setAcc();
        accessabilityButton();
    });

// produkteinzelseite höhe berechnen
function setProductFamilyHeight() {

    const productSpace = document.querySelector(
        '#product-col-first');
    if (typeof (productSpace) !=
        'undefined' &&
        productSpace !=
        null) {

        var width = productSpace.offsetWidth /
            10 *
            6;

        var counter = 0;
        document.querySelectorAll(
            '.product-col').forEach(
            function(el) {
                counter++;
            });

        var heightRowProductfamily = width *
            3;
        /*
        if (counter > 4) {
            var heightRowProductfamily = width * 3;
        } else {
            var heightRowProductfamily = width * 2;
        }
        */

        const rowProductfamily = document.querySelector(
            '.row-productfamily');
        rowProductfamily.style.height = heightRowProductfamily +
            "px";

        document.querySelectorAll(
            '.teaser-slider.image-slider .slide').forEach(
            function(el) {
                el.style.height = heightRowProductfamily +
                    "px";
            });
        document.querySelectorAll(
            '.teaser-slider.image-slider .slide img').forEach(
            function(el) {
                el.style.height = heightRowProductfamily +
                    "px";
            });

    }

}

import Lightbox
    from 'bs5-lightbox';

function setLightbox() {
    document.querySelectorAll(
        '.lightbox').forEach(
        el => el.addEventListener(
            'click',
            Lightbox.initialize)
    );

    let lightboxFullscreen = document.querySelectorAll(
        '.lightbox-fullscreen');
    lightboxFullscreen.forEach(
        (item) => {
            item.addEventListener(
                'click',
                () => {
                    document.querySelector(
                        'body').classList.add(
                        "show-lightbox-fullscreen");
                });
        });
}

setLightbox();

function callback(
    mutationList,
    observer) {
    mutationList.forEach(
        function(mutation) {
            if (mutation.type ===
                'attributes' &&
                mutation.attributeName ===
                'class') {
                if (mutation.target.className.includes(
                        "modal-open") ==
                    false &&
                    mutation.target.className.includes(
                        "show-lightbox-fullscreen") ==
                    true) {
                    var body = document.querySelector(
                        'body');
                    body.classList.remove(
                        'show-lightbox-fullscreen');
                }
            }
        })
}

document.addEventListener(
    'DOMContentLoaded',
    async () => {

        const observer = new MutationObserver(
            callback)
        const options = {
            attributes: true
        }
        var body = document.querySelector(
            'body');
        observer.observe(
            body,
            options);

    });
