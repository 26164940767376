import {
    sessionStorageGet,
    sessionStorageSet,
} from './local-storage';
import Splide
    from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', async () => {

    const debug = 0;

    const containerProductfamily = document.querySelector(".container-productfamily");
    const productfotosmobile = document.querySelector("#productfotosmobile");
    const productfotodesktop = document.querySelector("#productfotodesktop");
    if(typeof(containerProductfamily) != 'undefined' && containerProductfamily != null) {
        window.onresize = function() {
            showProductphotos(window);
        };
        showProductphotos(window);

        function showProductphotos(window){

            if (debug > 0) console.log("in function showProductphotos ");

            var isMobile = false;
            var isDesktop = false;
            var url = "";
            var div = "";
            var screenDesktop = window.matchMedia("(min-width: 992px)")
            if (screenDesktop.matches) {
                var isDesktop = true;
            } else {
                var isMobile = true;
            }

            const body = document.body;
            var siteId = body.getAttribute('data-siteid');

            if (isDesktop == true) {
                if (debug > 0) console.log("show desktop");
                productfotosmobile.classList.add("d-none");
                productfotosdesktop.classList.remove("d-none");
            } else {
                if (debug > 0) console.log("show mobile");
                productfotosdesktop.classList.add("d-none");
                productfotosmobile.classList.remove("d-none");
            }

            var countervarianten = productfotosmobile.getAttribute('data-countervarianten');
            var entryid = productfotosmobile.getAttribute('data-entryid');
            var filterstrbf000067 = productfotosmobile.getAttribute('data-filterstrbf000067');
            var filterstrbf000059 = productfotosmobile.getAttribute('data-filterstrbf000059');
            var filterstrbf000070 = productfotosmobile.getAttribute('data-filterstrbf000070');
            
            // desktop laden
            if (isDesktop == true && containerProductfamily.classList.contains("hide-desktop")) {
                containerProductfamily.classList.remove("hide-desktop");
                if (debug > 0) console.log("load desktop");
                var url = '/ajax/row-productfamily-desktop?entryid=' + entryid + '&countervarianten=' + countervarianten
                    + '&filterstrbf000067=' + filterstrbf000067
                    + '&filterstrbf000059=' + filterstrbf000059
                    + '&filterstrbf000070=' + filterstrbf000070
                    + '&siteId=' + siteId;

                if (debug > 0) console.log("ajax url : " + url);
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);

                // function execute after request is successful
                xhr.onreadystatechange = function () {
                    if (this.readyState == 4 && this.status == 200) {
                        //console.log(this.responseText);
                        productfotosdesktop.innerHTML = this.responseText;
                        //setProductFamilyHeight();
                        countActiveProducts();
                    }
                }
                // Sending our request
                xhr.send();

            }

            if (isDesktop == false && containerProductfamily.classList.contains("hide-mobile")) {
                containerProductfamily.classList.remove("hide-mobile");
                if (debug > 0) console.log("load mobile");
                var url = '/ajax/row-productfamily-mobile?entryid=' + entryid + '&countervarianten=' + countervarianten
                    + '&filterstrbf000067=' + filterstrbf000067
                    + '&filterstrbf000059=' + filterstrbf000059
                    + '&filterstrbf000070=' + filterstrbf000070
                    + '&siteId=' + siteId;

                if (debug > 0) console.log("ajax url : " + url);
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);

                // function execute after request is successful
                xhr.onreadystatechange = function () {
                    if (this.readyState == 4 && this.status == 200) {
                        //console.log(this.responseText);
                        productfotosmobile.innerHTML = this.responseText;


                        var elms = document.getElementsByClassName( 'splide' );
                        for ( var i = 0; i < elms.length; i++ ) {
                            new Splide( elms[ i ] ).mount();
                        }
                        countActiveProducts();

                    }
                }
                // Sending our request
                xhr.send();

            }

        }
        
    }

    window.onscroll = function() {
    //    fixFilter(window);
    };
    //fixFilter(window);


    function fixFilter(window) {

        if (debug > 0) console.log("in function fixFilter: " + window);
        
        // Get the filterbar
        const body = document.body;
        const filterbar = document.querySelector(".pre-filter");
        const filters = document.querySelector(".container-fluid-filters");
        const containermasonry1 = document.querySelector(".container-masonry-1");

        if(typeof(filters) != 'undefined' && filters != null){
            if (debug > 0) console.log("filters.offsetHeight: " + filters.offsetHeight);

            var filterbarOffsetTop = filterbar.offsetTop;
            var windowScrollY = window.scrollY;
            var currentFilterbarOffset = (filterbarOffsetTop - windowScrollY);

            if (debug > 1) console.log("currentFilterbarOffset: " + currentFilterbarOffset);

            var switchOffset = 90;
            if (currentFilterbarOffset <= switchOffset) {
                body.classList.add("filterbar-fixed");
                //containermasonry1.style.marginTop = filters.offsetHeight + "px";
                collapseFilter();
            } else {
                body.classList.remove("filterbar-fixed");
            }
        }
    }

    function collapseFilter(method = "") {
        if (debug > 0) console.log("in collapseFilter");
        document.querySelectorAll( '.container-fluid-filters .col-filters' ).forEach(function(el){
            if (el.classList.contains('stayopen') && method != "onclick") {
            } else {
                el.classList.add("close");
                el.classList.remove("open");
            }
        });
    }
    function openFilter(method = "") {
        if (debug > 0) console.log("in openFilter");
        document.querySelectorAll( '.container-fluid-filters .col-filters' ).forEach(function(el){
            el.classList.remove("close");
            el.classList.add("open");
            el.classList.add("stayopen");
        });
    }

    // show/hide filter
    var filterOnOff = document.querySelector('.filter-on-off');
    if(typeof(filterOnOff) != 'undefined' && filterOnOff != null){
        filterOnOff.addEventListener('click', function() {
            if (debug > 0) console.log("filter-on-off");
            document.querySelectorAll( '.container-fluid-filters .col-filters' ).forEach(function(el){
                if (el.classList.contains("open")) {
                    collapseFilter('onclick');
                } else {
                    openFilter('onclick');
                }
            });
        });

        // auf kleinen screens nur icon anzeigen
        var screenWidth = window.matchMedia("(max-width: 575px)")
        if (screenWidth.matches) {
            if (debug > 0) console.log("collapse filter on mobile");
            collapseFilter();
        }

    }


    // reset all filters pl
    var resetProductFamilyFilter = document.querySelector('.resetProductFamilyFilter');
    if(typeof(resetProductFamilyFilter) != 'undefined' && resetProductFamilyFilter != null){
        resetProductFamilyFilter.addEventListener('click', function() {

            var activeFilter = document.querySelector('.active-filter.keyword');
            activeFilter.classList.remove("d-inline-block");
            activeFilter.classList.add("d-none");

            resetAllFilters();
        });
    }

    // neu
    var productSubCategoriesFilter = document.querySelector('.productSubCategoriesFilter');
    if(typeof(productSubCategoriesFilter) != 'undefined' && productSubCategoriesFilter != null){
        productSubCategoriesFilter.addEventListener('click', function() {
            document.querySelectorAll('#accordionProductFamilyFilter input[type=checkbox]').forEach(function(el){
                el.checked = false;
            });
        });
    }



    function resetAllFilters() {
        /*
        document.querySelectorAll('.productFamilyFilter input').forEach(function(el){
            el.checked = true;
        });
        */
        document.querySelectorAll('.toasts .toast').forEach(function(elToast){
            elToast.classList.remove("show");
            elToast.classList.add("hide");
        });
        var filterCountx = document.querySelectorAll('.filter-count');
        filterCountx.forEach((element) => {
            element.innerHTML = 0;
        });

        showAllPL();
    }
    resetAllFilters();

    // show all pl
    function showAllPL() {
        document.querySelectorAll( '.result-type-filter' ).forEach(function(el){
            el.classList.remove("d-none");
            el.classList.remove("filterBF000067-hide");
            el.classList.remove("filterBF000059-hide");
            el.classList.remove("filterBF000070-hide");
        });
    }

    // filter buttons plp
    document.querySelectorAll('.productFamilyFilter .label-wrapper').forEach(function(el){
        el.addEventListener('click', function(event) {
            
            if (debug > 0) console.log(".productFamilyFilter .label-wrapper -> click");

            document.querySelectorAll('.toasts .toast').forEach(function(elToast){
                elToast.classList.remove("show");
                elToast.classList.add("hide");
            });

            var countFilterActive = 0;

            // doppelte ausführung abfangen
            if (event.target.localName == "input") {
                // welche filter sind gesetzt
                var filterBF000067 = [];
                var filterStrBF000067 = [];
                document.querySelectorAll('.productFamilyFilter input.button-type-filter-BF000067:checked').forEach(function(el){
                    countFilterActive++;
                    var bf000067 = el.getAttribute('data-keyBF000067');
                    filterBF000067.push(bf000067);
                    var toast = document.querySelector('.toast[data-filter="'+bf000067+'"]');
                    toast.classList.remove("hide");
                    toast.classList.add("show");
                    var strbf000067 = el.getAttribute('data-keyStrBF000067');
                    filterStrBF000067.push(strbf000067);
                });

                var filterBF000059 = [];
                var filterStrBF000059 = [];
                document.querySelectorAll('.productFamilyFilter input.button-type-filter-BF000059:checked').forEach(function(el){
                    countFilterActive++;
                    var bf000059 = el.getAttribute('data-keyBF000059');
                    filterBF000059.push(bf000059);
                    var toast = document.querySelector('.toast[data-filter="'+bf000059+'"]');
                    toast.classList.remove("hide");
                    toast.classList.add("show");
                    var strbf000059 = el.getAttribute('data-keyStrBF000059');
                    filterStrBF000059.push(strbf000059);
                });

                var filterBF000070 = [];
                var filterStrBF000070 = [];
                document.querySelectorAll('.productFamilyFilter input.button-type-filter-BF000070:checked').forEach(function(el){
                    countFilterActive++;
                    var bf000070 = el.getAttribute('data-keyBF000070');
                    filterBF000070.push(bf000070);
                    var toast = document.querySelector('.toast[data-filter="'+bf000070+'"]');
                    toast.classList.remove("hide");
                    toast.classList.add("show");
                    var strbf000070 = el.getAttribute('data-keyStrBF000070');
                    filterStrBF000070.push(strbf000070);
                });

                var activeFilter = document.querySelector('.active-filter');
                if (countFilterActive == 0) {
                    activeFilter.classList.remove("d-inline-block");
                    activeFilter.classList.add("d-none");
                    document.querySelectorAll('.cta-productlist-wrapper').forEach(function(el){
                        el.classList.remove("d-none");
                    });
                } else {
                    activeFilter.classList.remove("d-none");
                    activeFilter.classList.add("d-inline-block");
                    document.querySelectorAll('.cta-productlist-wrapper').forEach(function(el){
                        el.classList.add("d-none");
                    });
                }

                sessionStorageSet("filterBF000067", filterBF000067);
                sessionStorageSet("filterBF000059", filterBF000059);
                sessionStorageSet("filterBF000070", filterBF000070);

                sessionStorageSet("filterStrBF000067", filterStrBF000067);
                sessionStorageSet("filterStrBF000059", filterStrBF000059);
                sessionStorageSet("filterStrBF000070", filterStrBF000070);

                // alles einblenden
                document.querySelectorAll( '.result-type-filter' ).forEach(function(el){
                    showAllPL();
                });

                document.querySelectorAll( '.result-type-filter' ).forEach(function(el){

                    if(filterBF000067.length > 0) {
                        var bf000067 = el.getAttribute('data-keyBF000067');
                        for ( var i = 0; i < filterBF000067.length; i++ ) {
                            if (bf000067.includes(filterBF000067[i]) == false) {
                                el.classList.add("filterBF000067-hide");
                            }
                        }
                    }
                    if(filterBF000059.length > 0) {
                        var bf000059 = el.getAttribute('data-keyBF000059');
                        for ( var i = 0; i < filterBF000059.length; i++ ) {
                            if (bf000059.includes(filterBF000059[i]) == false) {
                                el.classList.add("filterBF000059-hide");
                            }
                        }
                    }
                    if(filterBF000070.length > 0) {
                        var bf000070 = el.getAttribute('data-keyBF000070');
                        for ( var i = 0; i < filterBF000070.length; i++ ) {
                            if (bf000070.includes(filterBF000070[i]) == false) {
                                el.classList.add("filterBF000070-hide");
                            }
                        }
                    }

                    if (el.classList.contains("filterBF000067-hide") || el.classList.contains("filterBF000059-hide") || el.classList.contains("filterBF000070-hide")) {
                        el.classList.add("d-none");
                        el.classList.remove("filterBF000067-hide");
                        el.classList.remove("filterBF000059-hide");
                        el.classList.remove("filterBF000070-hide");
                    }
                });

                // set urls for family-links
                document.querySelectorAll( '.result-type-filter a' ).forEach(function(el){
                    var href = el.getAttribute('href');
                    var hrefArr = href.split('forward=true');
                    var hrefNew = hrefArr[0] + "forward=true" + "&filterStrBF000067=" + filterStrBF000067 + "&filterStrBF000059=" + filterStrBF000059 + "&filterStrBF000070=" + filterStrBF000070;
                    el.setAttribute('href', hrefNew);
                });
            }

            sessionStorage.clear()
        });
    });


    /* plpFilter handling */
    /*
    const filterObserver = new IntersectionObserver(items => {
        items.forEach(item => {
            if(item.intersectionRatio == 0) {
                document.getElementById('plpFilterFixed').classList.remove('d-none');
            } else {
                document.getElementById('plpFilterFixed').classList.add('d-none');
            }
        });
    }, { rootMargin: '-70px', threshold: 0 }); //rootmargin -> height of navigation
    const filterButton = document.querySelectorAll('#plpFilter');
    filterButton.forEach(el => {
        filterObserver.observe(el);
    });

     */
});


// live checked counter of filters
const checkBoxes = document.querySelectorAll('#accordionProductFamilyFilter input[type="checkbox"]');
const filterCount = document.querySelectorAll('.filter-count');
checkBoxes.forEach(i => {
    //console.log("drin1");
    i.addEventListener('change', () => {
        //console.log("drin2");
        let cbCount = document.querySelectorAll('#accordionProductFamilyFilter input[type="checkbox"]:checked').length.toString();
        filterCount.forEach((element) => {
            element.innerHTML = cbCount;
        });
    })
});

const isPartlyInViewport = function(el, percentVisible) {
    let rect = el.getBoundingClientRect();
    let windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    return !(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
};

function isInViewport(el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}




// produkteinzelseite höhe berechnen
function setProductFamilyHeight() {

    const productSpace = document.querySelector('#product-col-first');
    if(typeof(productSpace) != 'undefined' && productSpace != null){

        var width = productSpace.offsetWidth / 10 * 6;

        var counter = 0;
        document.querySelectorAll('.product-col').forEach(function(el){
            counter++;
        });

        var heightRowProductfamily = width * 3;
        /*
        if (counter > 4) {
            var heightRowProductfamily = width * 3;
        } else {
            var heightRowProductfamily = width * 2;
        }
        */

        const rowProductfamily = document.querySelector('.row-productfamily');
        rowProductfamily.style.height = heightRowProductfamily + "px";

        document.querySelectorAll('.teaser-slider.image-slider .slide').forEach(function(el){
            el.style.height = heightRowProductfamily + "px";
        });
        document.querySelectorAll('.teaser-slider.image-slider .slide img').forEach(function(el){
            el.style.height = heightRowProductfamily + "px";
        });

    }
}

function countActiveProducts() {
    
    // varianten zählen
    const varDiv = document.querySelector('.some-variants');
    if(typeof(varDiv) != 'undefined' && varDiv != null){

        // wieviele sind active?

        const activeVariants1 = document.querySelector('.filter-mobile .active-variants');
        if(typeof(activeVariants1) != 'undefined' && activeVariants1 != null) {
            
            var counter = 0;
            document.querySelectorAll('#productfotosmobile .product-col.product-filter-active').forEach(function(el){
                counter++;
            });

            activeVariants1.textContent = counter;
            const variant1m = document.querySelector('.filter-mobile .variant1');
            const variant2m = document.querySelector('.filter-mobile .variant2');

            if (counter == 1) {
                variant1m.classList.remove("d-none");
                variant2m.classList.add("d-none");
            } else {
                variant1m.classList.add("d-none");
                variant2m.classList.remove("d-none");
            }

        }
        const activeVariants2 = document.querySelector('.filter-desktop .active-variants');
        if(typeof(activeVariants2) != 'undefined' && activeVariants2 != null) {

            var counter = 0;
            document.querySelectorAll('#productfotosdesktop .product-col.product-filter-active').forEach(function(el){
                counter++;
            });

            activeVariants2.textContent = counter;
            const variant1d = document.querySelector('.filter-desktop .variant1');
            const variant2d = document.querySelector('.filter-desktop .variant2');

            if (counter == 1) {
                variant1d.classList.remove("d-none");
                variant2d.classList.add("d-none");
            } else {
                variant1d.classList.add("d-none");
                variant2d.classList.remove("d-none");
            }
        }

    }

}
