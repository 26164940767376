function storageHas(key) {
    return localStorage.getItem(key) !== null;
}
function storageGet(key) {
    return JSON.parse(localStorage.getItem(key));
}
function storageSet(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export { storageHas, storageGet, storageSet };

function sessionStorageHas(key) {
    return sessionStorage.getItem(key) !== null;
}
function sessionStorageGet(key) {
    return JSON.parse(sessionStorage.getItem(key));
}
function sessionStorageSet(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
}

export { sessionStorageHas, sessionStorageGet, sessionStorageSet };
