document.addEventListener('DOMContentLoaded', async () => {

    const body = document.body;
    const debug = 0;

    var startEntries = 16;
    var masonryContainer = document.querySelector('.container-masonry');
    if(typeof(masonryContainer) != 'undefined' && masonryContainer != null){
        var startEntries = masonryContainer.getAttribute('data-startentries');
    }

    // filter aus storage setzen
    var filterTagIdsStr = localStorage.getItem('filterTagIds');
    if(typeof(filterTagIdsStr) != 'undefined' && filterTagIdsStr != null){
        if (filterTagIdsStr.length > 0) {
            if (debug > 0) console.log("function set tags from local storage");
            var filterTagIds = filterTagIdsStr.split(',');
            for ( var i = 0; i < filterTagIds.length; i++ ) {
                document.querySelectorAll('.filter-button[data-id="'+filterTagIds[i]+'"]').forEach(function(el){
                    el.classList.add('active');
                });
            }
        }
    }

    // filter aus storage setzen
    var entrytypeArrStr = localStorage.getItem('entrytypeArr');
    if(typeof(entrytypeArrStr) != 'undefined' && entrytypeArrStr != null){
        if (entrytypeArrStr.length > 0) {
            if (debug > 0) console.log("function getFilterEntrytypeFromStorage");
            var button = document.querySelector('[data-entrytype="'+entrytypeArrStr+'"]');
            if(typeof(button) != 'undefined' && button != null){
                button.classList.add('active');
            }
        }
    }

    showMasonry(startEntries);

    // masonry home
    function showMasonry(numberentries){
        if (debug > 0) console.log("function showMasonry");
        var masonryContainer = document.querySelector('.container-masonry');
        if(typeof(masonryContainer) != 'undefined' && masonryContainer != null){
            masonryContainer.setAttribute('data-numberentries', numberentries);
            showEntries();
        }
    }

    // show entries
    function showEntries() {
        if (debug > 0) console.log("function showEntries");

        // welcher entrytype ist active
        var entrytypeArr = getFilterEntrytype();

        // wieviele entrytype existieren
        var numberentrytypes = document.querySelector('.container-masonry').getAttribute('data-numberentrytype');

        // welche tags sind active
        var filterTagIds = getFilterTagIds();

        // wieviele tags existieren
        var numberfilters = document.querySelector('.container-masonry').getAttribute('data-numberfilters');

        // alle entries ausblenden
        var colsmsnry = document.getElementsByClassName( 'colmsnry' );
        for ( var i = 0; i < colsmsnry.length; i++ ) {

            colsmsnry[i].classList.add("d-none");
            colsmsnry[i].classList.remove("d-block");

            // filtern oder nicht?
            if ((entrytypeArr.length == 0 && filterTagIds.length == numberfilters) ||
                (entrytypeArr.length == 0 && filterTagIds.length == 0)) {
                // alle buttons bzw. keiner inaktiv => alles anzeigen
                colsmsnry[i].classList.remove("d-none");
                colsmsnry[i].classList.add("d-block");
            }
            else {

                document.querySelectorAll( '.cta-home-wrapper' ).forEach(function(el){
                    el.classList.add("d-none");
                });

                if (entrytypeArr.length != numberentrytypes && entrytypeArr.length != 0) {
                    // entrytype button aktiv => filtern
                    var colEntrytype = colsmsnry[i].getAttribute('data-entrytype');
                    if (entrytypeArr.includes("entrytype-" + colEntrytype) == true) {
                        colsmsnry[i].classList.remove("d-none");
                        colsmsnry[i].classList.add("d-block");
                        if (filterTagIds.length > 0 && filterTagIds.length < numberfilters) {
                            var colFilterIds = colsmsnry[i].getAttribute('data-filterId');
                            var commonFilterIds = filterTagIds.filter(i => colFilterIds.includes(i));
                            if (commonFilterIds.length > 0) {
                                colsmsnry[i].classList.remove("d-none");
                                colsmsnry[i].classList.add("d-block");
                            } else {
                                colsmsnry[i].classList.remove("d-block");
                                colsmsnry[i].classList.add("d-none");
                            }
                        }
                    }
                }
                else if (filterTagIds.length > 0 && filterTagIds.length < numberfilters) {
                    var colFilterIds = colsmsnry[i].getAttribute('data-filterId');
                    var commonFilterIds = filterTagIds.filter(i => colFilterIds.includes(i));
                    if (commonFilterIds.length > 0) {
                        colsmsnry[i].classList.remove("d-none");
                        colsmsnry[i].classList.add("d-block");
                    } else {
                        colsmsnry[i].classList.remove("d-block");
                        colsmsnry[i].classList.add("d-none");
                    }
                }
            }

        }

        if ((entrytypeArr.length > 0 || filterTagIds.length > 0)) {
            body.classList.add("filteractive");
            makeBoxesSmall();
        } else {
            body.classList.remove("filteractive");
            removeBoxesSmall();
        }

        /*
        console.log("entrytypeArr.length: " + entrytypeArr.length);
        console.log("filterTagIds.length: " + filterTagIds.length);
        console.log("numberentrytypes: " + numberentrytypes);
        console.log("numberfilters: " + numberfilters);
        */

        if ((entrytypeArr.length != numberentrytypes || filterTagIds.length != numberfilters) ||
            (entrytypeArr.length != numberentrytypes && filterTagIds.length != 0)) {
            createLayout();
        }

        hideEntries();

    }

    // makeBoxesSmall
    function makeBoxesSmall(){
        if (debug > 0) console.log("function makeBoxesSmall");
        document.querySelectorAll( '.colmsnry.d-block' ).forEach(function(el){
            if (el.classList.contains("col-lg-4")) {
                el.classList.add("col-lg-4-temp");
                el.classList.remove("col-lg-4");
            }
        });
    }

    // removeBoxesSmall 
    function removeBoxesSmall(){
        if (debug > 0) console.log("function makeBoxesSmall");
        document.querySelectorAll( '.colmsnry.d-block' ).forEach(function(el){
            if (el.classList.contains("col-lg-4-temp")) {
                el.classList.remove("col-lg-4-temp");
                el.classList.add("col-lg-4");
            }
        });
    }

    // layout erstellen
    function createLayout(){
        // console.log("function createLayout");

        var countEntries = 0;

        document.querySelectorAll( '.colmsnry.d-block' ).forEach(function(el){
            if (el.classList.contains("layout-fix")) {
            } else {
                el.classList.remove("col-lg-4");
                countEntries++;
                if (countEntries == 0  + 2 || countEntries == 0  + 6 || countEntries == 0  + 7 ||
                    countEntries == 13 + 2 || countEntries == 13 + 6 || countEntries == 13 + 7 ||
                    countEntries == 26 + 2 || countEntries == 26 + 6 || countEntries == 26 + 7) {
                    //el.classList.add("col-lg-4");
                }
            }
        });

    }

    // überflüssige entries ausblenden
    function hideEntries() {
        // console.log("function hideEntries");

        var countEntries = 0;
        var showMoreButton = false;
        var numberentries = document.querySelector('.container-masonry').getAttribute('data-numberentries');

        document.querySelectorAll( '.colmsnry.d-block' ).forEach(function(el){
            countEntries++;
            if (el.classList.contains('col-lg-4')) {
                countEntries++;
            }
            if (countEntries > numberentries) {
                el.classList.remove("d-block");
                el.classList.add("d-none");
            }
        });

        if (countEntries > numberentries) {
            showMoreButton = true;
        }

        var moreButton = document.querySelector('.moreButton');
        if(typeof(moreButton) != 'undefined' && moreButton != null) {
            if (showMoreButton == false) {
                document.querySelector('.moreButton').classList.add("d-none");
            } else {
                document.querySelector('.moreButton').classList.remove("d-none");
            }
        }
    }

    // more button
    var moreButton = document.querySelector('.moreButton');
    if(typeof(moreButton) != 'undefined' && moreButton != null){

        // auf click prüfen
        moreButton.addEventListener('click', function() {
            // console.log("click moreButton");
            loadMore();
        });

        // auf scroll prüfen
        const observeLoadMoreButton = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (debug > 0) console.log("observeLoadMoreButton isIntersecting -> loadMore");
                        loadMore();
                    }
                })
            }
        );
        observeLoadMoreButton.observe(moreButton);

    }


    // load more entries
    function loadMore(){
        var numberentries = document.querySelector('.container-masonry').getAttribute('data-numberentries');
        var numberentrieslimit = document.querySelector('.container-masonry').getAttribute('data-numberentrieslimit');
        numberentries = Number(numberentries) + Number(numberentrieslimit);
        document.querySelector('.container-masonry').setAttribute('data-numberentries', numberentries);
        showEntries();
        showCTAs();
    }

    // ctas einblenden
    function showCTAs() {

        //console.log('function showCTAs')

        var ctaHome = document.querySelector('.cta-home');
        if(typeof(ctaHome) != 'undefined' && ctaHome != null) {
            document.querySelectorAll(
                '.cta-home').forEach(
                function(el) {
                    el.classList.remove(
                        'd-none');
                });
        }
    }

    // welcher entrytype ist active
    function getFilterEntrytype() {
        if (debug > 0) console.log("function getFilterEntrytype");
        if (debug > 0) console.log("set localStorage for entrytypeArr");

        var entrytypeArr = [];
        var filterButtonEntrytype = document.querySelector('.filter-button-entrytype');
        if(typeof(filterButtonEntrytype) != 'undefined' && filterButtonEntrytype != null) {
            document.querySelectorAll('.filter-button-entrytype.active').forEach(function(el){
                entrytypeArr.push(el.getAttribute('data-entrytype'));
            });
            if (entrytypeArr.length > 0) {
                var entrytypeArrStr = entrytypeArr.join(',');
                localStorage.setItem('entrytypeArr', entrytypeArrStr);
            } else {
                localStorage.setItem('entrytypeArr', '');
            }
        }
        return entrytypeArr;
    }

    // welche tags sind active
    function getFilterTagIds() {
        if (debug > 0) console.log("function getFilterTagIds");
        
        var filterTagIds = [];
        var filterButtonTag = document.querySelector('.filter-button-tag');
        if(typeof(filterButtonTag) != 'undefined' && filterButtonTag != null) {
            document.querySelectorAll('.filter-button-tag.active').forEach(function(el){
                filterTagIds.push(el.getAttribute('data-id'));
            });
            if (filterTagIds.length > 0) {
                var filterTagIdsStr = filterTagIds.join(',');
                localStorage.setItem('filterTagIds', filterTagIdsStr);
            } else {
                localStorage.setItem('filterTagIds', '');
            }
        }
        return filterTagIds;
    }


    // filter buttons
    document.querySelectorAll('.filter-button').forEach(function(el){
        el.addEventListener('click', function() {

            if (debug > 0) console.log("filter button");

            var masonryContainer = document.querySelector('.container-masonry');
            if(typeof(masonryContainer) != 'undefined' && masonryContainer != null) {
                masonryContainer.setAttribute(
                    'data-numberentries',
                    startEntries);
            }

            // buttons auf active/inactive setzen
            if (el.classList.contains('filter-button-entrytype')) {
                if (el.classList.contains('active')) {
                    el.classList.remove("active");
                } else {
                    // entrytype
                    document.querySelectorAll('.filter-button-entrytype').forEach(function(el){
                        el.classList.remove("active");
                    });
                    el.classList.add("active");
                }
            }
            else {
                // tag
                if (el.classList.contains('active')) {
                    el.classList.remove("active");
                } else {
                    el.classList.add("active");
                }
            }

            showEntries();

        });
    });

    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('show')) {
        let param = searchParams.get('show')
        if (param == "wissen") {
            var button = document.querySelector('[data-entrytype="entrytype-default"]');
            button.click();
        }
        else if (param == "projects") {
            var button = document.querySelector('[data-entrytype="entrytype-project"]');
            button.click();
        }
    }

});
